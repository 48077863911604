.reportHeader {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: rgba(0,0,0,.03);
}
.reportTitle {
  font-size: 1.5rem;
  font-weight: 500;
  float: left;
  padding-left: 12px;
  padding-right: 20px;
}
.reportDateRange {
  max-width: 200px;
  float: left;  
}
.reportButtons {
  max-width: 200px;
  float: right;
  padding-right: 12px;
}

.formControl {
  width: 180px;
  height: 40px;
  line-height: 38px;
  border-radius: 6px;
  border: 1px solid #ced4da;
  font-family: "Open Sans", sans-serif;
  font-size: 12pt;
  color: #000;
}

.tableWrapper {
  padding: 1rem;
}
.report {
  border-spacing: 0;
  border-radius: .25rem;
}
.tableWrapper tr {
  border: none;
}
.tableWrapper tr th {
  border: none;
  margin: 0;
  font-size: 16px;
}
.tableWrapper tr td {
  border: none;
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  padding-right: 12px;
}

.reportWrapper tr td button {
  text-decoration: underline;
}

