body {
  background-color: #fbfbfb;
}

p {
  font-size: 12pt;
  font-family: "Open Sans", sans-serif;
}

input {
  color: #000;
}

.container {
  max-width: 95%;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #e6ffe6;
}

.revision-str {
  font-size: xx-small;
  color: rgb(156, 156, 156); 
  margin-left: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

/* Override the form control validation styles */
.form-control {
  width: 280px;
  height: 40px;
  line-height: 38px;
  border-radius: 6px;
  border: 1px solid #ced4da;
  font-family: "Open Sans", sans-serif;
  font-size: 12pt;
  color: #000;
}

.form-control:focus {
  border: 1px solid #0a5397;
  box-shadow: 0 0 0 0 transparent;
  color: #000;
}

.was-validated .form-control:valid {
  border: 1px solid #ced4da;
  box-shadow: 0 0 0 0 transparent;
  background-image: none;
}

.was-validated .form-control:valid:focus {
  border: 1px solid #0a5397;
  box-shadow: 0 0 0 0 transparent;
  background-image: none;
}

.was-validated .form-control:invalid {
  border: 1px solid #ced4da;
  box-shadow: 0 0 0 0.2rem rgba(256, 256, 256, 0);
  background-color: #fff;
  background-image: none;
}

.was-validated .form-control:invalid:focus {
  border: 1px solid #0a5397;
  box-shadow: 0 0 0 0.2rem rgba(256, 256, 256, 0);
  background-color: #fff;
  background-image: none;
}

.invalid {
  border: 1px solid #bf2626 !important;
  box-shadow: 0 0 0 0.2rem rgba(256, 256, 256, 0);
  background-color: #f0f0f0;
  background-image: none;
}

.input-group {
  border-color: #ced4da;
}

/* This will be used only with the buttons that are appended to input fields. */
.input-group-append .btn {
  width: 50px;
  height: 40px;
  padding: 0;
  background-color: #fff;
  color: #0a5397;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-color: #ced4da;
  vertical-align: middle;
  text-align: center;
  line-height: 40px;
}

.invalid-feedback {
  font-family: "Open Sans", sans-serif;
  font-size: 9pt;
  text-align: left !important;
  float: left !important;
  color: #b02525;
}

.btn {
  height: 38px;
  border-radius: 6px;
  font-size: 12pt;
  font-family: "Open Sans", sans-serif;
  padding: 0;
  vertical-align: middle;
  line-height: 36px;
  text-align: center;
  background-repeat: no-repeat;
}

.btn:active {
  outline: none;
  transform: translateY(2px);
}

.btn:focus {
  text-decoration: underline;
}

.btn-xs {
	width: 80px;
	height: 38px;
}

.btn-sm {
  width: 130px;
  height: 38px;
}

.btn-lg {
  width: 200px;
  height: 38px;
}

.btn-primary:not(:disabled):not(.disabled):focus {
  background-color: #0a5397;
  border-color: #0a5397;
  color: #fff;
}

.btn-primary {
  background-color: #0a5397;
  color: #fff;
  border: 1px solid #0a5397;
}

.btn-primary:active,
.btn-primary:hover {
  background-color: #003d75;
  color: #f0f0f0;
  border: 1px solid #003d75;
}

.btn-danger:not(:disabled):not(.disabled):focus {
  background-color: #b02525;
  border-color: #b02525;
  color: #fff;
}

.btn-danger,
.btn-danger:active,
.btn-danger:hover {
  color: #b02525;
  background-color: #f0f0f0;
  border: 1px solid #b02525;
}

.btn-outline-primary:not(:disabled):not(.disabled):focus {
  color: #0a5397;
  background-color: #fff;
  border-color: #0a5397;
}

.btn-outline-primary {
  color: #0a5397;
  background-color: #fff;
  border-color: #0a5397;
}

.btn-outline-primary:active,
.btn-outline-primary:hover {
  color: #003d75;
  background-color: #fff;
  border-color: #003d75;
  border: 1px solid #003d75;
}

.btn-outline-primary:not(:disabled):not(.disabled):focus {
  color: #0a5397;
  background-color: #fff;
  border-color: #0a5397;
}

.btn-outline-danger {
  color: #b02525;
  background-color: #fff;
  border-color: #b02525;
}

.btn-outline-danger:active,
.btn-outline-danger:hover {
  color: #fff;
  background-color: #b02525;
  border-color: #fff;
}

.btn-link {
  color: #0a5397;
}

.btn-link:hover {
  text-decoration: underline;
}

.input-group {
  height: 40px;
  width: 280px;
  font-family: "Open Sans", sans-serif;
  border-radius: 6px;
  font-size: 12pt;
}

.input-group .form-control {
  border-right: transparent !important;
}

.input-group .form-control:focus {
  border-right: transparent !important;
}

.input-group .form-control:invalid:focus {
  border-right: transparent !important;
}

.input-group .form-control:valid:focus {
  border-right: transparent !important;
}

.input-group .btn {
  border-color: #ced4da;
  box-shadow: 0 0 0 0 transparent;
  border-left: transparent !important;
}

.input-group .btn:focus {
  border-color: #ced4da;
  box-shadow: 0 0 0 0 transparent;
  border-left: transparent !important;
}

.input-group .btn:active {
  border-color: #ced4da;
  box-shadow: 0 0 0 0 transparent;
  border-left: transparent !important;
  transform: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8a8a8a;
  opacity: 1; /* Firefox */
  font-size: 12pt;
  font-family: "Open Sans", sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8a8a8a;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8a8a8a;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > label {
  position: absolute;
  top: 6px;
  left: 11.7px;
  float: left;
  display: block;
  margin-bottom: 0; /* Override default `<label>` margin */
  padding: 0px 0px 0px 0px;
  font-size: 12pt;
  color: #8a8a8a;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.05s ease-in-out;
  z-index: 3;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  top: -25%;
  width: auto;
  white-space: nowrap;
  background-color: #fff;
  margin: 0px 0px -10px 0px;
  font-size: 9pt;
  color: #000;
  display: inline-block;
  z-index: 3;
}

.alert {
  position: absolute;
  width: 310px;
  left: 50%;
  margin-left: -155px;
  z-index: 99;
  border-radius: 6px;
  background-color: #f0f0f0;
  padding: 2px 10px 0px 10px;
}

.alert-info {
  border: 1px solid #2cb025;
}
.alert-heading-info {
  margin-bottom: -2px;
  color: #2cb025;
}
.alert-message-info {
  width: 50;
  margin: 0px 0px 2px 0px;
  font-size: 10pt;
  color: #2cb025;
  line-height: 14pt;
}

.alert-warning {
  border: 1px solid #b0a425;
}
.alert-heading-warning {
  margin-bottom: -2px;
  color: #b0a425;
}
.alert-message-warning {
  width: 50;
  margin: 0px 0px 2px 0px;
  font-size: 10pt;
  color: #b0a425;
  line-height: 14pt;
}

.alert-danger {
  border: 1px solid #b02525;
}
.alert-heading-danger {
  margin-bottom: -2px;
  color: #b02525;
}
.alert-message-danger {
  width: 50;
  margin: 0px 0px 2px 0px;
  font-size: 10pt;
  color: #b02525;
  line-height: 14pt;
}

.nav-tabs .nav-link.active {
    color: #0a5397;
    background-color: rgba(0,0,0,.03);
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.card {
    border-top: none;
}

.pagination button{
    border: none;
    border-radius: 4px;
    height: 32px;
    margin: 0px 14px;
    width: 32px;
}

.pagination button {
    background: rgba(255, 255, 255, 0.842);
    left: 0;
    transition: border-color 200ms linear 0ms;
    transition-property: background-color, color;
}

.pagination button:not(:disabled):hover {
    background-color: #0a5397;
    color: #FFF;
    transition: border-color 200ms linear 0ms;
    transition-property: background-color, color;
}

.paginationFooterRight {
  text-align: right;
}

.paginationFooterMid {
  text-align: center;
}

.paginationFooterLeft {
  text-align: left;
}

.paginationGoToPage {
  height: 40px;
  width: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
    font-size: 12pt;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.form-select {
    display: block;
    width: 100%;
    height: 40px;
    padding: .375rem 2.25rem .375rem .75rem;
    font-family: "Open Sans", sans-serif;
    font-size: 12pt;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.form-select-page {
  width: 150px;
  height: 40px;
  padding: .375rem 2.25rem .375rem .75rem;
  font-family: "Open Sans", sans-serif;
  font-size: 12pt;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.form-control-reports {
  width: 100%;
  height: 40px;
  line-height: 38px;
  border-radius: 6px;
  border: 1px solid #ced4da;
  font-family: "Open Sans", sans-serif;
  font-size: 12pt;
  color: #000;
}

.fogotpassword-container {
  margin-top:110px;
  margin-right:auto;
  margin-left:auto;
  width: 290px;
  height: 350px;

  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}